import React from 'react';

import {
    Section,
    Container,
    Row,
    Col,
    className,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';
import PointComponent from '../PointComponent';
import MediaComponent from '../MediaComponent';

export default function ValuesSection({ points, title, picture }) {
    return (
        <Section
            className={`text-basic-100 bg-primary-light-500 ${style.wrapper}`}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 5 }}
                        order={{ xs: 1 }}
                        offset={{ lg: 1 }}
                        mb={{ xs: 9, lg: 6 }}
                        data-sal="slide-left">
                        {picture && (
                            <MediaComponent
                                title={title}
                                picture={picture}
                                aspectRatio={1.5}
                                className={style.media}
                                videoClassName={`${style.video} ${
                                    picture.width / picture.height > 1.5
                                        ? style.videoWider
                                        : style.videoHigher
                                } `}></MediaComponent>
                        )}
                    </Col>
                    {points.map((point, index) => {
                        return (
                            <Col
                                key={index}
                                col={{ xs: 12, lg: 4 }}
                                offset={{ lg: 1 }}
                                order={{
                                    xs: index > 5 ? 2 : '',
                                    lg: index > 4 ? 2 : '',
                                }}
                                mb={{ xs: 9, lg: 13, xxl: 15 }}>
                                <PointComponent
                                    number={point.number}
                                    title={point.title}
                                    delay={
                                        [1, 4, 6, 8].indexOf(index) !== -1
                                            ? 200
                                            : 0
                                    }
                                    description={
                                        point.description
                                    }></PointComponent>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Section>
    );
}
