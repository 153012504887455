import React from 'react';
import PropTypes from 'prop-types';

import {
    Text,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from 'IDCButton';
import ImageComponent from '../ImageComponent';
import defaultImage from '../../img/default-image.png';
import style from './style.module.scss';

export default function TeamCard({
    name,
    image,
    charge,
    linkedin,
    cv,
    entity,
    wrapperClassName,
    onClickDetail,
    delay,
}) {
    return (
        <div className={wrapperClassName ? wrapperClassName : ''}>
            <div className={`${style.teamCard}`}>
                <ImageComponent
                    image={!!image ? image : defaultImage}
                    alt={name}
                    className={style.teamCardImage}
                    imgStyle={{
                        objectPosition: '50% 0%',
                    }}
                />
                <div
                    {...className(
                        `${style.teamCardInfo} bg-primary-light-600`,
                    )}>
                    <div
                        {...className(
                            `pt-4 pb-3 pt-lg-3 pt-xxl-4 pl-3 pl-xxl-4 pr-3 pr-xxl-5 ${style.teamCardInfoName}`,
                        )}>
                        <Heading
                            tag="h4"
                            {...className(
                                `heading4 text-uppercase text-basic-100 ${style.teamCardName}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay}`}>
                            {name}
                        </Heading>
                        <Text
                            tag="p"
                            {...className(`normal text-basic-100`)}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {charge}
                        </Text>
                        <Text
                            tag="p"
                            {...className(`normal font-italic text-basic-100`)}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {entity}
                        </Text>
                    </div>
                    <div
                        {...className(
                            `d-flex justify-content-end align-items-center ${style.teamCardInfoButtons}`,
                        )}>
                        {cv && (
                            <ButtonComponent
                                button={{
                                    label: 'profile',
                                    Tag: 'button',
                                    type: 'primaryDark',
                                    onClick: () =>
                                        onClickDetail({
                                            name,
                                            image,
                                            charge,
                                            linkedin,
                                            cv,
                                        }),
                                }}
                            />
                        )}
                        {linkedin && (
                            <ButtonComponent
                                button={{
                                    externalLink: linkedin ?? '',
                                    label: 'linkedin',
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                    type: 'primaryMedium',
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

TeamCard.propTypes = {
    name: PropTypes.string,
    image: PropTypes.any,
    charge: PropTypes.string,
    linkedin: PropTypes.any,
    cv: PropTypes.string,
};
