import React from 'react';

import HeroSectionAlt from '../HeroSectionAlt';
import DescriptionSection from '../DescriptionSection';

import style from './style.module.scss';

export default function HeroAltDescriptionSection({
    heroAltSectionData,
    descriptionSectionData,
    order,
}) {
    return (
        <div className={style.wrapper} data-order={order}>
            <HeroSectionAlt
                {...heroAltSectionData}
                {...(order === 1 && {
                    bgProps: { 'data-navbar-height': true },
                })}
            />
            <DescriptionSection {...descriptionSectionData} />
        </div>
    );
}
