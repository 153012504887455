import React, { useMemo } from 'react';
import { StructuredText } from 'react-datocms';

import {
    Text,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const colorsArray = [
    'primary-light-500',
    'primary-light-600',
    'primary-light-700',
    'primary-light-800',
];

const colorsArrayCompact = ['primary-light-600', 'primary-light-500'];

export default function FastFactsCard({
    title,
    subtitle,
    body,
    order,
    compact,
    delay,
    wrapperClassName,
}) {
    const color = useMemo(
        () =>
            compact ? colorsArrayCompact[order % 2] : colorsArray[order % 4],
        [order, compact],
    );

    return (
        <div
            {...className(
                `${style.portfolioCard} bg-${color} ${'flex-column'} '' ${
                    wrapperClassName ? wrapperClassName : ''
                }`,
            )}>
            <Heading
                tag="h3"
                {...className(
                    `heading4 text-uppercase text-basic-100 mb-2 ${style.heading}`,
                )}
                data-sal="slide-up"
                data-sal-delay={`xs-none--lg-${delay + 100}`}>
                {title}
            </Heading>
            <div className={style.infoContainer}>
                {subtitle && (
                    <Heading
                        tag="h3"
                        {...className(
                            `heading1 font-weight-bold text-uppercase text-basic-200 ${style.subtitle}`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {subtitle}
                    </Heading>
                )}
                {body && (
                    <div className={style.struturedText}>
                        <StructuredText data={body?.value} />
                    </div>
                )}
            </div>
        </div>
    );
}
