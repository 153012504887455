import React from 'react';
import style from './style.module.scss';
import HeroSectionAlt from '../HeroSectionAlt/index';
import DescriptionSection from '../DescriptionSection/index';

const VideoSection = ({
    variant,
    title,
    subtitle,
    bgProps = null,
    urlVideo,
    nextSectionType,
}) => {
    return (
        <div>
            <HeroSectionAlt
                title={title}
                variant={variant}
                bgProps={bgProps}
                nextSectionType="DatoCmsFeatureSection"
            />
            <DescriptionSection
                title={subtitle}
                nextSectionType="DatoCmsFeatureSection"
            />
            <div className={style.videoContainer}>
                <video autoPlay loop muted playsInline>
                    <source src={urlVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default VideoSection;
