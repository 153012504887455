import React, { useMemo, useState } from 'react';
import { StructuredText } from 'react-datocms';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Heading,
    Text,
    Section,
} from '@solublestudio/react-bootstrap-components';

import MediaComponent from '../MediaComponent';

import style from './style.module.scss';

export default function HeroSection({
    title,
    subtitle,
    description,
    picture,
    order,
    ispopupactive,
    popuptitle,
    popuptext,
    popupbuttontext,
    popupbuttonlink,
}) {
    const multilineTitle = useMemo(
        () => title?.trim(' ').trim(`\n`).replace(`\n`, '<br />') || '',
        [title],
    );

    const [email, setEmail] = useState('');
    const [isPopUpActive, setIsPopUpActive] = useState(true);
    const [subscribeMessage, setSubscribeMessage] = useState(false);
    const closePopup = () => setIsPopUpActive(false);

    const saveEmail = async (ev) => {
        ev.preventDefault();
        try {
            const URL = 'https://newpaper-emails.herokuapp.com/api/newsletter';
            await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                }),
            });
            setSubscribeMessage(true);
            setEmail('');
        } catch (err) {
            console.log(err);
        } finally {
            setTimeout(() => {
                setSubscribeMessage(false);
            }, 2500);
        }
    };

    const showSeparator = popuptitle && popuptext && popupbuttontext;
    const boxPadding = {
        padding: '32px 30px 64px 30px',
    };

    return (
        <Section
            className={`${style.section} ${
                order === 1 ? style.firstSection : ''
            }`}>
            {ispopupactive && isPopUpActive ? (
                <div className={style.PopUp}>
                    <section
                        className={style.PopUp__box}
                        style={!showSeparator ? boxPadding : {}}>
                        <div className={style.PopUp__box__closeButton}>
                            <button onClick={closePopup}>X</button>
                        </div>
                        <div className={style.PopUp__box__body}>
                            <h5 className={style.PopUp__box__body__title}>
                                {popuptitle}
                            </h5>
                            <p className={style.PopUp__box__body__paragraph}>
                                <StructuredText data={popuptext} />
                            </p>
                            {popupbuttontext && (
                                <button
                                    className={
                                        style.PopUp__box__body__linkButton
                                    }>
                                    <a
                                        href={popupbuttonlink}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {popupbuttontext}
                                    </a>
                                </button>
                            )}
                        </div>
                        {showSeparator && (
                            <div className={style.PopUp__box__separator}></div>
                        )}
                        <div className={style.PopUp__box__subscribe}>
                            <span
                                className={style.PopUp__box__subscribe__label}>
                                Subscribe to Newsletter
                            </span>
                            <form onSubmit={(ev) => saveEmail(ev)}>
                                <div
                                    className={
                                        style.PopUp__box__subscribe__email
                                    }>
                                    <input
                                        required
                                        type="email"
                                        value={email}
                                        onChange={({ target }) =>
                                            setEmail(target?.value)
                                        }
                                        placeholder="Enter your E-mail"
                                    />
                                    <button type="submit">Subscribe</button>
                                </div>
                            </form>
                        </div>
                        {subscribeMessage && (
                            <p className={style.PopUp__box__message}>
                                Successful subscription!
                            </p>
                        )}
                    </section>
                </div>
            ) : null}

            <div className={style.background}>
                {picture ? (
                    <MediaComponent
                        className={
                            picture.isImage
                                ? style.backgroundImage
                                : style.backgroundVideo
                        }
                        picture={picture}
                    />
                ) : null}
            </div>
            <Container className={style.container}>
                <Row className="text-uppercase">
                    <Col
                        mb={
                            subtitle || description
                                ? { xs: 6, lg: 13, xxl: 15 }
                                : {}
                        }
                        className={style.wrapperTitle}>
                        <Heading
                            tag="h1"
                            className={`display2 text-secondary ${style.title}`}
                            data-sal="slide-left">
                            {multilineTitle}
                        </Heading>
                    </Col>
                    {subtitle ? (
                        <Col
                            mb={{ xs: 4, lg: 0 }}
                            offset={{ lg: 1 }}
                            col={{ lg: 6 }}>
                            <Text
                                tag="p"
                                className="heading1"
                                data-sal="slide-left"
                                data-sal-delay="xs-none--lg-400">
                                {subtitle}
                            </Text>
                        </Col>
                    ) : null}
                    {description ? (
                        <Col offset={{ lg: 1 }} col={{ xs: 6, lg: 3 }}>
                            <Text
                                tag="div"
                                className="heading4 text-secondary"
                                data-sal="slide-left"
                                data-sal-delay="xs-none--lg-800">
                                {description}
                            </Text>
                        </Col>
                    ) : null}
                </Row>
            </Container>
        </Section>
    );
}

HeroSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.any,
    order: PropTypes.number,
};
