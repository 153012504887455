import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Heading, className } from '@solublestudio/react-bootstrap-components';

import Modal from '../Modal';

import style from './style.module.scss';

const VideoPopup = forwardRef(({ video, title }, ref) => {
    const [url, setUrl] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && !window.YT) {
            let scr = document.createElement('script'),
                head =
                    document.head || document.getElementsByTagName('head')[0];
            scr.src = 'https://www.youtube.com/iframe_api';
            head.insertBefore(scr, head.firstChild);
            const onLoad = () => {
                setMounted(true);
            };
            if (scr) {
                scr.addEventListener('load', onLoad);
            }
            return () => {
                scr.removeEventListener('load', onLoad);
            };
        } else if (typeof window !== 'undefined' && window.YT) {
            setMounted(true);
        }
    }, []);

    useEffect(() => {
        let player = null;
        if (
            mounted &&
            typeof window !== 'undefined' &&
            window.YT &&
            video &&
            modalOpen
        ) {
            window.YT.ready(function () {
                player = new window.YT.Player('player', {
                    videoId: video.url.split('v=')[1],
                });
                setUrl(player);
            });
        }
    }, [mounted, video, modalOpen]);

    const onToggle = useCallback(
        (open) => {
            if (!open && url?.stopVideo) {
                url.stopVideo();
            }

            setModalOpen(open);
        },
        [url],
    );

    return (
        <Modal ref={ref} onToggle={onToggle}>
            <Heading
                tag="h3"
                {...className(
                    `heading3 text-uppercase text-primary-900 pt-1 pl-1 pb-4 pt-lg-3 pl-lg-3 pb-lg-8 pt-xxl-4 pl-xxl-4 pb-xxl-12 ${style.videoTitle}`,
                )}>
                {title}
            </Heading>

            {modalOpen ? (
                <div className={style.wrapperVideo}>
                    <div id="player" />
                </div>
            ) : null}
        </Modal>
    );
});

VideoPopup.propTypes = {
    url: PropTypes.shape({
        url: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
};

export default VideoPopup;
