import React from 'react';

import {
    GridContainer,
    GridBox,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from 'IDCButton';
import ImageComponent from '../ImageComponent';
import FeatureTextComponent from '../FeatureTextComponent';
import style from './style.module.scss';

export default function FeatureDetailedSection({
    preTitle,
    title,
    description,
    ctas,
    picture,
}) {
    return (
        <GridContainer
            Tag="section"
            className={`${style.featureDetailedSection}`}>
            <GridBox
                className={`text-primary-900 pt-10 pb-9 pt-lg-20 pb-lg-20 pt-xxl-21 pb-xxl-21 ${style.featureDetailedSectionText}`}>
                <FeatureTextComponent
                    preTitle={preTitle}
                    title={title}
                    description={description}
                />
                {ctas && (
                    <div
                        {...className(
                            `mt-3 mt-lg-5 ${style.featureDetailedButtons}`,
                        )}>
                        {ctas.map((cta, index) => {
                            return (
                                <div
                                    key={index}
                                    data-sal="slide-right"
                                    data-sal-delay={300 + 300 * index}
                                    {...className(
                                        `mb-3 ${
                                            index === 0 ? 'mr-lg-5' : ''
                                        } d-inline-block`,
                                    )}>
                                    <ButtonComponent
                                        key={index}
                                        button={{
                                            ...cta,
                                            type: 'secondaryArrow',
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </GridBox>
            {picture && (
                <GridBox className={`${style.featureDetailedSectionImageBox}`}>
                    <ImageComponent
                        image={picture}
                        alt={title}
                        className={style.featureDetailedSectionImage}
                    />
                </GridBox>
            )}
        </GridContainer>
    );
}
