import React, { useMemo } from 'react';

import {
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';

import style from './style.module.scss';

export default function DescriptionSection({
    pretitle,
    title,
    order,
    nextSectionType,
}) {
    const triangle = useMemo(
        () =>
            (order === 2 &&
                (nextSectionType.indexOf('Portfolio') !== -1 ||
                    nextSectionType.indexOf('Team') !== -1)) ||
            nextSectionType === 'DatoCmsFeatureSection',
        [order, nextSectionType],
    );

    const light = useMemo(
        () =>
            order >= 4 &&
            (nextSectionType.indexOf('Portfolio') !== -1 ||
                nextSectionType.indexOf('Team') !== -1),
        [order, nextSectionType],
    );

    return (
        <>
            <BackgroundSection
                tag="section"
                type={triangle ? 'triangle' : 'rectangle'}
                colors={
                    triangle
                        ? [
                              'primaryLight600',
                              'primaryLight500',
                              'primaryLight800',
                          ]
                        : light
                        ? ['secondary', 'basic100']
                        : nextSectionType === 'DatoCmsHighlightedSection'
                        ? ['primaryLight600']
                        : ['primaryLight500', 'primaryLight700']
                }
                vertexAlignment={{ xs: 4 }}
                bgClassName={
                    !triangle
                        ? `${style.bgSection} ${
                              pretitle ? style.bgSectionWithPretitle : ''
                          }`
                        : ''
                }
                bgProps={
                    !triangle
                        ? {
                              'data-sal': 'slide-left',
                          }
                        : null
                }
                data-hide-on-filter>
                <Container
                    className={`text-uppercase font-weight-normal text-basic-${
                        light ? 900 : 100
                    } pt-12 pb-12 pt-lg-10 pb-lg-15 pt-xxl-12 pb-xxl-25`}>
                    <Row>
                        <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
                            {pretitle && (
                                <Heading
                                    tag="h2"
                                    className={`heading4 mb-3 mb-lg-5 mb-xxl-6`}
                                    data-sal="slide-left"
                                    {...(!triangle && {
                                        'data-sal-delay': 600,
                                    })}>
                                    {pretitle}
                                </Heading>
                            )}
                            <Heading
                                tag={pretitle ? 'p' : 'h4'}
                                data-sal="slide-left"
                                data-sal-delay={triangle ? 200 : 800}
                                className={`heading2`}>
                                {title}
                            </Heading>
                        </Col>
                    </Row>
                </Container>
            </BackgroundSection>
        </>
    );
}
