export const createModal = (country) => {
    if (country?.url) {
        let urls = '';
        country?.url?.forEach((src) => {
            urls += `
                <div class="companyModal__imgContainer">
                    <img src="${src}" class="companyModal__imgContainer__img" />
                </div>`;
        });
        let template = `
            <div class='companyModal'>
               ${urls}
            </div>
        `;
        return template;
    }
    return `<h5>No data registered</h5>`;
};
