import React, { useState, useEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_worldHigh from '@amcharts/amcharts4-geodata/worldHigh';
import { createModal } from './utils';
import { adapter, getISO3166, markerData, getCoords } from './adapter';
import { useIsMobile } from '@solublestudio/react-bootstrap-components';
import styles from './style.module.scss';
import './style.css';

const WorldMapSection = ({ showworldmap, companies, markers }) => {
    const isMobile = useIsMobile();
    const dataCountries = getISO3166(companies);
    let map = useRef(null);
    let polygonSeries = useRef(null);
    let polygonTemplate = useRef(null);
    let interestingSeries = useRef(null);
    let interestingTemplate = useRef(null);
    let imageSeries = useRef(null);
    const currentCountries = Object.keys(dataCountries);

    useEffect(() => {
        // CREATING MAP
        am4core.useTheme(am4themes_animated);
        map.current = am4core.create('chartdiv', am4maps.MapChart);
        // INTERESTING COUNTRIES
        interestingSeries.current = map.current.series.push(
            new am4maps.MapPolygonSeries(),
        );
        interestingSeries.current.include = currentCountries; // INCLUDE ONLY ONES
        interestingSeries.current.useGeodata = true;
        interestingTemplate.current =
            interestingSeries.current.mapPolygons.template;
        interestingTemplate.current.tooltipText = null;
        interestingTemplate.current.fill = am4core.color('#242BD3');
        let hs = interestingTemplate.current.states.create('hover');
        hs.properties.fill = am4core.color('#171A82');
        // REST OF COUNTRIES
        polygonSeries.current = map.current.series.push(
            new am4maps.MapPolygonSeries(),
        );
        polygonSeries.current.exclude = ['AQ', ...currentCountries]; // EXCLUDE ALL
        polygonSeries.current.useGeodata = true;
        polygonTemplate.current = polygonSeries.current.mapPolygons.template;
        polygonTemplate.current.fill = am4core.color('#c5c7d3');
        polygonTemplate.current.events.on('hit', () =>
            map.current.closeAllPopups(),
        );
        // ======================================================================
        // TYPE OF MAP
        map.current.geodata = am4geodata_worldHigh;
        map.current.projection = new am4maps.projections.Miller();
        // ======================================================================
        // ZOOM
        map.current.chartContainer.wheelable = false;
        map.current.seriesContainer.draggable = false;
        map.current.seriesContainer.resizable = false;
        map.current.maxZoomLevel = 1;
        // ======================================================================
        // SHOW ( latitude, longitude ) ON CLICK
        map.current.seriesContainer.events.on('hit', (ev) => {
            map.current.closeAllPopups();
            const data = map.current.svgPointToGeo(ev.svgPoint);
            console.log(getCoords(data));
        });
        // ======================================================================
        // COUNTRIES LOGIC
        interestingSeries.current.data = adapter(companies);
        interestingTemplate.current.propertyFields.fill = 'fill';
        interestingTemplate.current.events.on('hit', function (ev) {
            const toDo = () => {
                map.current.closeAllPopups();
                const popup = map.current.openPopup('-');
                const currentData = ev.target.dataItem.dataContext;
                /*
                fill: color,
                id: "BR",
                isCompany: true,
                multiPolygon: [],
                name: "Brazil",
                url: [images]
            */
                popup.left = ev.svgPoint.x + 8;
                popup.top = ev.svgPoint.y + 8;
                if (!currentData.url) return popup.close();
                popup.content = createModal(currentData);
            };
            setTimeout(toDo, 200);
        });
        // ======================================================================
        // MARKERS
        imageSeries.current = map.current.series.push(
            new am4maps.MapImageSeries(),
        );
        let imageSeriesTemplate = imageSeries.current.mapImages.template;
        imageSeries.current.data = markerData(
            markers,
            dataCountries,
            companies,
        );
        imageSeriesTemplate.events.on('hit', (ev) => {
            const toDo = () => {
                map.current.closeAllPopups();
                const popup = map.current.openPopup('-');
                const currentData = ev.target.dataItem.dataContext;
                /*
                image: "https://marker-back.herokuapp.com/2.png"
                latitude: 65.02152379142078
                longitude: 27.122334914369862
                name: "FI"
            */
                popup.left = ev.svgPoint.x + 8;
                popup.top = ev.svgPoint.y + 8;
                if (!currentData.url) return popup.close();
                popup.content = createModal(currentData);
            };
            setTimeout(toDo, 200);
        });
        imageSeriesTemplate.propertyFields.latitude = 'latitude';
        imageSeriesTemplate.propertyFields.longitude = 'longitude';
        let marker = imageSeriesTemplate.createChild(am4core.Image);
        marker.width = 38;
        marker.height = 38;
        marker.nonScaling = true;
        marker.tooltipText = null;
        marker.propertyFields.href = 'image';
        marker.horizontalCenter = 'middle';
        marker.verticalCenter = 'bottom';

        return () => {
            if (map.current) map.current.dispose();
        };
    }, []);

    useEffect(() => {
        if (map.current) {
            if (isMobile) {
                const isExist = map.current.series.indexOf(imageSeries.current);
                if (isExist !== -1) map.current.series.removeIndex(2).dispose();
            } else {
                const isExist = map.current.series.indexOf(imageSeries.current);
                if (isExist === -1) {
                    imageSeries.current = map.current.series.push(
                        new am4maps.MapImageSeries(),
                    );
                    let imageSeriesTemplate =
                        imageSeries.current.mapImages.template;
                    imageSeries.current.data = markerData(
                        markers,
                        dataCountries,
                        companies,
                    );
                    imageSeriesTemplate.events.on('hit', (ev) => {
                        const toDo = () => {
                            map.current.closeAllPopups();
                            const popup = map.current.openPopup('-');
                            const currentData = ev.target.dataItem.dataContext;
                            /*
                            image: "https://marker-back.herokuapp.com/2.png"
                            latitude: 65.02152379142078
                            longitude: 27.122334914369862
                            name: "FI"
                        */
                            popup.left = ev.svgPoint.x + 8;
                            popup.top = ev.svgPoint.y + 8;
                            if (!currentData.url) return popup.close();
                            popup.content = createModal(currentData);
                        };
                        setTimeout(toDo, 200);
                    });
                    imageSeriesTemplate.propertyFields.latitude = 'latitude';
                    imageSeriesTemplate.propertyFields.longitude = 'longitude';
                    let marker = imageSeriesTemplate.createChild(am4core.Image);
                    marker.width = 38;
                    marker.height = 38;
                    marker.nonScaling = true;
                    marker.tooltipText = null;
                    marker.propertyFields.href = 'image';
                    marker.horizontalCenter = 'middle';
                    marker.verticalCenter = 'bottom';
                }
            }
        }
    }, [isMobile]);

    return showworldmap ? (
        <section className={styles.WorldMapSection}>
            <div className={styles.WorldMapSection__container}>
                <div
                    className={styles.WorldMapSection__chartBox}
                    id="chartdiv"></div>
                <div className={styles.WorldMapSection__hiddenBox}></div>
            </div>
        </section>
    ) : null;
};
export default WorldMapSection;
