import React from 'react';

export const Play = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.25 6.44501V33.555L13.1638 32.305L32.305 20L13.1625 7.69501L11.25 6.44501ZM13.75 11.0163L27.695 20L13.75 28.985V11.0163Z"
            fill="black"
        />
    </svg>
);

export const Linkedin = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.1429 16.6429C21.4529 15.3043 23.1586 14.4286 25.1429 14.4286C27.2267 14.4286 29.2252 15.2564 30.6987 16.7299C32.1722 18.2034 33 20.2019 33 22.2857V33H30.1429V22.2857C30.1429 20.9596 29.6161 19.6879 28.6784 18.7502C27.7407 17.8125 26.4689 17.2857 25.1429 17.2857C23.8168 17.2857 22.545 17.8125 21.6073 18.7502C20.6696 19.6879 20.1429 20.9596 20.1429 22.2857V33H17.2857V15.1429H20.1429V16.6429ZM10.1429 12.2857C9.57454 12.2857 9.02949 12.0599 8.62763 11.6581C8.22576 11.2562 8 10.7112 8 10.1429C8 9.57454 8.22576 9.02949 8.62763 8.62763C9.02949 8.22576 9.57454 8 10.1429 8C10.7112 8 11.2562 8.22576 11.6581 8.62763C12.0599 9.02949 12.2857 9.57454 12.2857 10.1429C12.2857 10.7112 12.0599 11.2562 11.6581 11.6581C11.2562 12.0599 10.7112 12.2857 10.1429 12.2857ZM8.71429 15.1429H11.5714V33H8.71429V15.1429Z"
            fill="black"
        />
    </svg>
);

export const ExternalLink = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.5 6.25V8.75H29.4538L14.1 24.1012L15.8975 25.8988L31.25 10.5463V17.5H33.75V6.25H22.5ZM6.25 11.25V33.75H28.75V17.5L26.25 20V31.25H8.75V13.75H20L22.5 11.25H6.25Z"
            fill="black"
        />
    </svg>
);

export const Close = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.4853 27.071L12.929 11.5147L11.5147 11.5147L11.5147 12.9289L27.071 28.4852L28.4853 28.4853V27.071Z"
            fill="#00093D"
        />
        <path
            d="M27.0711 11.5148L11.5148 27.0711L11.5148 28.4854L12.929 28.4854L28.4853 12.929L28.4854 11.5148H27.0711Z"
            fill="#00093D"
        />
    </svg>
);

export const Profile = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4143 16.8429C15.8602 16.8429 17.8429 14.8601 17.8429 12.4143C17.8429 9.96845 15.8602 7.98571 13.4143 7.98571C10.9685 7.98571 8.98577 9.96845 8.98577 12.4143C8.98577 14.8601 10.9685 16.8429 13.4143 16.8429ZM13.4143 19.0571C17.0831 19.0571 20.0572 16.083 20.0572 12.4143C20.0572 8.74553 17.0831 5.77142 13.4143 5.77142C9.74559 5.77142 6.77148 8.74553 6.77148 12.4143C6.77148 16.083 9.74559 19.0571 13.4143 19.0571Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8569 22.8214C9.50591 22.8214 6.10686 26.1037 6.10686 29.9997V33.2286H3.89258V29.9997C3.89258 24.7439 8.42449 20.6071 13.8569 20.6071C19.2892 20.6071 23.8211 24.7439 23.8211 29.9997V33.2286H21.6069V29.9997C21.6069 26.1037 18.2078 22.8214 13.8569 22.8214Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.9996 9.53573H23.5996V7.32144H35.9996V9.53573Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.9996 13.9643H23.5996V11.75H35.9996V13.9643Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.6853 18.3928H23.5996V16.1786H30.6853V18.3928Z"
            fill="black"
        />
    </svg>
);
