import React, { useMemo } from 'react';

import { Slider } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

function Carousel({ children, settings = {}, className }, ref) {
    const sliderSettings = useMemo(
        () => ({
            controls: false,
            loop: false,
            navAsThumbnails: false,
            mouseDrag: true,
            arrowKeys: false,
            navPosition: 'bottom',
            edgePadding: 20,
            gutter: 0,
            autoplayButtonOutput: false,
            nav: true,
            mode: 'gallery',
            ...settings,
        }),
        [settings],
    );

    return (
        <div
            className={[style.slider, className]
                .filter((el) => !!el)
                .join(' ')}>
            <Slider parentRef={ref} settings={sliderSettings}>
                {children}
            </Slider>
        </div>
    );
}

export default React.forwardRef(Carousel);
