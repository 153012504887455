import React from 'react';

import VideoInline from '@solublestudio/react-bootstrap-components/src/components/VideoInline';
import style from './style.module.scss';
import ImageComponent from '../ImageComponent';

export default function ValuesSection({
    title,
    picture,
    aspectRatio,
    videoClassName,
    className,
    alt,
}) {
    return !picture?.isImage ? (
        <VideoInline
            className={`${className ? className : ''} ${style.videoWrapper} ${videoClassName ? videoClassName : ''
                }`}
            sources={[
                {
                    src: picture?.file?.publicURL,
                    type: picture?.file?.extension,
                },
            ]}
        />
    ) : picture ? (
        <ImageComponent
            image={picture}
            alt={alt}
            alt={title}
            className={`${className ? className : ''} ${style.imgWrapper}`}
            aspectRatio={aspectRatio}
        />
    ) : null;
}
