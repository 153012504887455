import React from 'react';
import PropTypes from 'prop-types';

import {
    Col,
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';
import Modal from '../Modal';
import ImageComponent from '../ImageComponent';
import HTMLTextComponent from '../HTMLTextComponent';
import ButtonComponent from 'IDCButton';

import defaultImage from '../../img/default-image.png';

import style from './style.module.scss';

const TeamPopUp = ({ member }, ref) => {
    return (
        <Modal ref={ref}>
            <div
                {...className(
                    'd-flex flex-column flex-lg-row position-relative',
                )}>
                <Col col={{ xs: 12, lg: 4 }} className={style.wrapperImage}>
                    <ImageComponent
                        image={!!member?.image ? member.image : defaultImage}
                        className={style.image}
                        imgStyle={{
                            objectPosition: '50% 0%',
                        }}
                    />
                </Col>
                <Col
                    col={{ xs: 12, lg: 8 }}
                    pt={{ xs: 3, lg: 5 }}
                    pb={{ xs: 3, lg: 5 }}
                    className={[style.wrapperContent].join(' ')}>
                    <Heading
                        className={`heading3 text-primary-900 text-uppercase mb-5 mb-lg-13 ${style.title}`}
                        tag="h3">
                        {member?.name}
                    </Heading>
                    <Text className="medium text-primary-medium-800" tag="p">
                        {member?.charge}
                    </Text>
                </Col>
                {member?.linkedin && (
                    <ButtonComponent
                        button={{
                            externalLink: member.linkedin,
                            className: style.btnImage,
                            type: 'primaryMedium',
                            label: 'linkedin',
                            target: 'blank',
                        }}
                    />
                )}
            </div>

            <BackgroundSection
                type="triangle"
                colors={['secondary', 'secondary', 'basic100']}
                vertexAlignment={{ xs: 4, lg: 4 }}
                disabledContainer>
                <Col
                    className={[style.wrapperContent].join(' ')}
                    col={{ xs: 12, lg: 8 }}
                    offset={{ lg: 4 }}
                    pt={{ xs: 5, lg: 6 }}
                    pb={{ xs: 5, lg: 6 }}>
                    <HTMLTextComponent
                        text={member?.cv}
                        className={style.text}
                    />
                </Col>
            </BackgroundSection>
        </Modal>
    );
};

TeamPopUp.propTypes = {
    member: PropTypes.shape({
        name: PropTypes.string,
        charge: PropTypes.string,
        linkedin: PropTypes.shape({}),
        cv: PropTypes.string,
        image: PropTypes.shape({}),
    }).isRequired,
};

export default React.forwardRef(TeamPopUp);
