import React, {
    useMemo,
    useCallback,
    useRef,
    useState,
    useEffect,
} from 'react';

import {
    GridContainer,
    GridBox,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import PortfolioCard from '../PortfolioCard';
import PortfolioCard2 from '../PortfolioCard2';
import VideoPopup from '../VideoPopup';
import ImageComponent from '../ImageComponent';
import BackgroundSection from '../BackgroundSection';
import Filters from '../Filters';
import style from './style.module.scss';
import style2 from './style2.module.scss';
import {
    makeHashable,
    setupSalAfterFilter,
    setupItemsOnFilter,
} from '../../utils';

const listToMatrix = (list, elementsPerSubArray) => {
    let matrix = [],
        k = -1;
    list.forEach((el, i) => {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }
        matrix[k].push(list[i]);
    });
    return matrix;
};

const addOrderToItems = (items) =>
    items.map((item, i) => ({
        ...item,
        order: i,
    }));

export default function PortfolioListSection({
    portfolio = [],
    categories,
    image1,
    image2,
    allCategoriesText,
    compact,
    pretitle,
}) {
    const refModal = useRef({});
    const sectionRef = useRef();
    const [itemSelected, setItemSelected] = useState({});
    const [categorySelected, setCategorySelected] = useState();
    const [isPortfolio, setIsPortfolio] = useState(true);
    const [isFunds, setIsFunds] = useState(true);

    const onClickVideo = useCallback((title, video) => {
        setItemSelected({
            title,
            video,
        });

        refModal?.current?.toggle();
    }, []);

    const [firstBox, secondBox, otherBoxes] = useMemo(() => {
        return setupItemsOnFilter(
            portfolio,
            categories,
            categorySelected,
            (portfolio) => {
                const portfolioOrdered = addOrderToItems(portfolio);
                return compact
                    ? [[], [], listToMatrix(portfolioOrdered, 3)]
                    : [
                          portfolioOrdered.slice(0, 2),
                          portfolioOrdered.slice(2, 4),
                          listToMatrix(
                              portfolioOrdered.slice(
                                  4,
                                  portfolioOrdered.length,
                              ),
                              3,
                          ),
                      ];
            },
            (portfolio) => [
                [],
                [],
                listToMatrix(addOrderToItems(portfolio), 3),
            ],
            () => [[], [], []],
        );
    }, [categories, portfolio, categorySelected, compact]);

    useEffect(() => makeHashable(setCategorySelected), [setCategorySelected]);

    useEffect(() => {
        if (categorySelected) {
            setupSalAfterFilter(sectionRef);
        }
    }, [categorySelected, sectionRef]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/portfolio/') {
            setIsPortfolio(true);
            setIsFunds(false);
        } else {
            setIsPortfolio(false);
            setIsFunds(true);
        }
    }, []);

    const renderCard = (card, index) => {
        return (
            <PortfolioCard
                key={index}
                {...card}
                compact={compact}
                wrapperClassName={style.card}
                onClickVideoButton={onClickVideo}
                delay={index * 200}
            />
        );
    };

    const renderCard2 = (card, index) => {
        return (
            <PortfolioCard2
                key={index}
                {...card}
                compact={compact}
                wrapperClassName={style2.card}
                onClickVideoButton={onClickVideo}
                delay={index * 200}
            />
        );
    };

    return (
        <BackgroundSection
            ref={sectionRef}
            tag="section"
            colors={compact ? ['primaryLight700'] : ['secondary']}
            type="solid"
            wrapperClassName={style.wrapper}
            {...(!categories?.length && { 'data-hide-on-filter': true })}
            {...(!!categorySelected && { 'data-is-filter': true })}>
            {!!categories?.length && (
                <div>
                    <Container>
                        <Row>
                            <Col>
                                <Filters
                                    wrapperClassName={style.filters}
                                    allCategoriesText={allCategoriesText}
                                    allCategoriesSlug="all"
                                    categorySelected={categorySelected}
                                    links={categories}></Filters>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
            {pretitle && (
                <Container>
                    <Row>
                        <Col offset={{ lg: 1 }}>
                            <Heading
                                tag="h4"
                                className="heading1 text-basic-100 text-uppercase mb-2 mb-xxl-3 mt-10 mt-lg-14 mt-xxl-21">
                                {pretitle}
                            </Heading>
                        </Col>
                    </Row>
                </Container>
            )}
            {isFunds && (
                <GridContainer>
                    {otherBoxes.map((box, i) => {
                        return (
                            <GridBox
                                key={i}
                                className={`${style.box} `}
                                atts={{ 'data-index': i }}>
                                {box.map((card, index) => {
                                    return renderCard(card, index);
                                })}
                            </GridBox>
                        );
                    })}
                </GridContainer>
            )}
            {isPortfolio && (
                <GridContainer>
                    <GridBox className={style2.box}>
                        {!!firstBox?.length && (
                            <>
                                {firstBox.map((card, index) => {
                                    return renderCard2(card, index);
                                })}
                            </>
                        )}
                        {!!secondBox?.length && (
                            <>
                                {secondBox.map((card, index) => {
                                    return renderCard2(card, index);
                                })}
                            </>
                        )}
                        {otherBoxes.map((box, i) => {
                            return box.map((card, index) => {
                                return renderCard2(card, index);
                            });
                        })}
                    </GridBox>
                </GridContainer>
            )}
            <VideoPopup ref={refModal} {...itemSelected} />
        </BackgroundSection>
    );
}
