import React, { forwardRef } from 'react';

import { Button } from '@solublestudio/react-bootstrap-components';
import { Play, Linkedin, ExternalLink, Close, Profile } from '../Icons';
import style from './style.module.scss';

const Arrow = () => (
    <svg
        width="11"
        height="20"
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 19.5L10.5 10L-8.30516e-07 0.5C-5.53678e-07 6.83333 -2.76839e-07 13.1667 0 19.5Z"
            fill="#252AD4"
        />
    </svg>
);

const ButtonComponent = forwardRef(
    ({ button = null, buttons = [], Tag = 'a', href = null }, ref) => {
        let theButtons = button ? [button] : buttons;

        return (
            <>
                {theButtons.map(
                    (
                        {
                            className,
                            block,
                            link,
                            isSubmit,
                            externalLink,
                            target,
                            label,
                            loading,
                            disabled,
                            arrow,
                            size,
                            type,
                            ...props
                        },
                        i,
                    ) => (
                        <Button
                            key={i}
                            ref={ref}
                            block={block ? true : false}
                            type={type}
                            className={`${style[type]} ${className}`}
                            size={size}
                            isGatsby={link && link.slug ? true : false}
                            isSubmit={isSubmit}
                            disabled={disabled}
                            Tag={
                                isSubmit
                                    ? 'button'
                                    : link && link.slug
                                    ? Tag
                                    : 'a'
                            }
                            href={
                                href
                                    ? href
                                    : link && link.slug
                                    ? `/${link.slug}/`
                                    : externalLink
                                    ? externalLink
                                    : '#'
                            }
                            target={target ? target : '_self'}
                            {...props}>
                            {type === 'secondaryArrow' ||
                            type === 'secondaryArrowLight' ? (
                                <>
                                    <Arrow></Arrow>
                                    <span>{label}</span>
                                </>
                            ) : null}
                            {type === 'primaryDark' ||
                            type === 'primaryMedium' ? (
                                <div className={style.hoverBox}>
                                    {label === 'play' ? (
                                        <Play></Play>
                                    ) : label === 'linkedin' ? (
                                        <Linkedin></Linkedin>
                                    ) : label === 'link' ? (
                                        <ExternalLink></ExternalLink>
                                    ) : label === 'close' ? (
                                        <Close></Close>
                                    ) : label === 'profile' ? (
                                        <Profile></Profile>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : null}
                        </Button>
                    ),
                )}
            </>
        );
    },
);

export default ButtonComponent;
