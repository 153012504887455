import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text } from '@solublestudio/react-bootstrap-components';

export default function FeatureTextComponent({
    preTitle,
    title,
    description,
    dark,
}) {
    return (
        <>
            <Heading
                tag="h2"
                className={`heading4 text-uppercase mb-3 mb-lg-5 mb-xxl-6 ${
                    dark ? 'text-basic-100' : 'text-primary-900'
                }`}
                data-sal="slide-right">
                {preTitle}
            </Heading>
            <Heading
                tag="h4"
                className={`heading2 text-uppercase mb-3 mb-xxl-5 ${
                    dark ? 'text-basic-100' : 'text-primary-900'
                }`}
                data-sal="slide-right"
                data-sal-delay="100">
                {title}
            </Heading>
            <Text
                tag="div"
                className={`medium ${
                    dark ? 'text-basic-100' : 'text-primary-900'
                }`}
                data-sal="slide-right"
                data-sal-delay="200">
                {description}
            </Text>
        </>
    );
}

FeatureTextComponent.defaultProps = {};

FeatureTextComponent.propTypes = {
    preTitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};
