import React, { useMemo } from 'react';

import {
    Text,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from 'IDCButton';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';

const colorsArray = [
    'primary-light-500',
    'primary-light-600',
    'primary-light-700',
    'primary-light-800',
];

const colorsArrayCompact = ['primary-light-600', 'primary-light-500'];

const IconLocation = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 6.66602C14 11.3327 8 15.3327 8 15.3327C8 15.3327 2 11.3327 2 6.66602C2 5.07472 2.63214 3.54859 3.75736 2.42337C4.88258 1.29816 6.4087 0.666016 8 0.666016C9.5913 0.666016 11.1174 1.29816 12.2426 2.42337C13.3679 3.54859 14 5.07472 14 6.66602Z"
            stroke="#D4D5F7"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 8.66602C9.10457 8.66602 10 7.77059 10 6.66602C10 5.56145 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 5.56145 6 6.66602C6 7.77059 6.89543 8.66602 8 8.66602Z"
            stroke="#D4D5F7"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default function PortfolioCard({
    order,
    companyName,
    primaryIcon,
    category,
    title,
    subtitle,
    region,
    video,
    externalLink,
    wrapperClassName,
    onClickVideoButton,
    delay,
    compact,
    isexit,
}) {
    const color = useMemo(
        () =>
            compact ? colorsArrayCompact[order % 2] : colorsArray[order % 4],
        [order, compact],
    );

    return (
        <div
            style={{ position: 'relative' }}
            {...className(
                `${!compact ? style.portfolioCard : ''} bg-${color} d-flex ${
                    !compact ? 'flex-column' : ''
                } justify-content-between ${
                    wrapperClassName ? wrapperClassName : ''
                }`,
            )}>
            {isexit && (
                <div className={style.exit_notice}>
                    <img
                        src="https://www.datocms-assets.com/46748/1647466561-exit.png"
                        title="exit-company"
                        alt="exit-logo"
                    />
                </div>
            )}
            <div {...className(`${!compact ? 'pt-3 pl-3 pr-3' : 'pl-4 pt-3'}`)}>
                {primaryIcon && (
                    <ImageComponent
                        image={primaryIcon}
                        alt={companyName}
                        aspectRatio={1.5}
                        className={`${style.portfolioCardImage} mb-3 ${
                            !compact ? 'mb-xxl-5' : ''
                        }`}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay}`}
                    />
                )}
                {!compact ? (
                    <Text
                        tag="p"
                        {...className(
                            `caption text-primary-light-200 text-uppercase mb-2`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {category}
                    </Text>
                ) : (
                    ''
                )}
                {!compact ? (
                    <Heading
                        tag="h3"
                        {...className(
                            `heading3 text-uppercase text-basic-100 mb-2`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {title}
                    </Heading>
                ) : (
                    ''
                )}
                {!compact ? (
                    <Text
                        tag="div"
                        {...className(`normal text-basic-100 mb-3 mb-xxl-4`)}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 200}`}>
                        {subtitle}
                    </Text>
                ) : (
                    ''
                )}
            </div>
            <div
                {...className(
                    `d-flex d ${
                        !compact ? 'align-items-center' : 'align-items-end'
                    } ${
                        region
                            ? 'justify-content-between'
                            : 'justify-content-end'
                    }`,
                )}>
                {!compact
                    ? region && (
                          <div {...className(`d-flex pl-3`)}>
                              <IconLocation></IconLocation>
                              <Text
                                  tag="p"
                                  {...className(
                                      `heading4 text-primary-100 ml-1 text-uppercase`,
                                  )}
                                  data-sal="slide-up"
                                  data-sal-delay={`xs-none--lg-${delay + 200}`}>
                                  {region}
                              </Text>
                          </div>
                      )
                    : ''}
                <div
                    {...className(
                        `d-flex justify-content-end align-items-center`,
                    )}>
                    {!compact
                        ? video && (
                              <ButtonComponent
                                  button={{
                                      Tag: 'button',
                                      label: 'play',
                                      type: 'primaryDark',
                                      onClick: () =>
                                          onClickVideoButton(
                                              companyName,
                                              video,
                                          ),
                                  }}
                              />
                          )
                        : ''}
                    {externalLink && (
                        <ButtonComponent
                            button={{
                                externalLink: externalLink ?? '',
                                label: 'link',
                                target: '_blank',
                                rel: 'noopener noreferrer',
                                type: 'primaryMedium',
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
