import React, { useMemo } from 'react';

import {
    Text,
    Heading,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from 'IDCButton';

import style from './style.module.scss';

const colorsArray = [
    'primary-light-500',
    'primary-light-600',
    'primary-light-700',
    'primary-light-800',
];

const colorsArrayCompact = ['primary-light-600', 'primary-light-500'];

export default function FastFactsCardII({
    order,
    region,
    wrapperClassName,
    delay,
    compact,

    title,
    logo,
    subtitle,
    linkImage,
    size,
    investmentAreas,
    isexit,
    description,
    websiteurl,
}) {
    const color = useMemo(
        () =>
            compact ? colorsArrayCompact[order % 2] : colorsArray[order % 4],
        [order, compact],
    );

    return (
        <div
            {...className(
                `${
                    !compact ? style.portfolioCard : ''
                } bg-primary-light-600 d-flex ${
                    !compact ? 'flex-column' : ''
                } justify-content-between ${
                    wrapperClassName ? wrapperClassName : ''
                }`,
            )}>
            <div>
                <div
                    className={style.sectionOne}
                    style={{
                        backgroundImage: `url(${linkImage})`,
                    }}>
                    <div className={style.sectionOne__left}>
                        <div className={style.sectionOne__left__img}>
                            <img src={logo} alt={title} title={title} />
                        </div>
                        <div className={style.sectionOne__left__subtitle}>
                            <Text
                                tag="p"
                                {...className(
                                    `text-primary-light-100 mb-2 ${style.subtitle2}`,
                                )}
                                data-sal="slide-up"
                                data-sal-delay={`xs-none--lg-${delay + 100}`}>
                                {subtitle}
                            </Text>
                        </div>
                    </div>
                </div>

                <div className={style.sectionTwo}>
                    <div
                        className={style.sectionTwo__item}
                        {...className('bg-primary-light-00')}>
                        <Text
                            tag="p"
                            {...className(
                                `caption text-primary-300 mb-1 ${style.dataItem}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            SIZE
                        </Text>
                        <Heading
                            tag="h3"
                            {...className(
                                `heading4 font-weight-bold text-uppercase text-basic-200 mb-2 ${style.dataItem2}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {size}
                        </Heading>
                    </div>
                    <div
                        className={style.sectionTwo__item}
                        {...className('bg-primary-light-00')}>
                        <Text
                            tag="p"
                            {...className(
                                `caption text-primary-300 mb-1 ${style.dataItem}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            INVESTMENT AREAS
                        </Text>
                        <Heading
                            tag="h3"
                            {...className(
                                `heading4 font-weight-bold text-uppercase text-basic-100 mb-2 ${style.dataItem2}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {investmentAreas}
                        </Heading>
                    </div>
                    <div
                        className={style.sectionTwo__item}
                        {...className('bg-primary-light-00')}>
                        <Text
                            tag="p"
                            {...className(
                                `caption text-primary-300 mb-1 ${style.dataItem}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            STATUS
                        </Text>
                        <Heading
                            tag="h3"
                            {...className(
                                `heading4 font-weight-bold text-uppercase text-basic-100 mb-2 ${style.dataItem2}`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {!isexit ? 'ACTIVE' : 'CLOSED'}
                        </Heading>
                    </div>
                </div>
                <div className={style.description}>
                    <Text
                        tag="p"
                        {...className(
                            `caption text-basic-100 ${style.descriptionFont}`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {description}
                    </Text>
                </div>
            </div>

            <div
                {...className(
                    `d-flex d ${
                        !compact ? 'align-items-center' : 'align-items-end'
                    } ${
                        region
                            ? 'justify-content-between'
                            : 'justify-content-end'
                    }`,
                )}>
                <ButtonComponent
                    button={{
                        externalLink: websiteurl ?? '',
                        label: 'link',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        type: 'primaryMedium',
                    }}
                />
            </div>
        </div>
    );
}

/*
========== TEXT ==============
                    <Text
                        tag="p"
                        {...className(
                            `caption text-primary-light-200 text-uppercase mb-2`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {category}
                    </Text>
====================================
<>
                <section>
                    <div>
                        <ImageComponent
                            image={logo}
                            alt={title}
                            aspectRatio={1.5}
                            className={`${style.portfolioCardImage2} mb-3 ${
                                !compact ? 'mb-xxl-5' : ''
                            }`}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay}`}
                        />
                    </div>
                    <div>
                        <Heading
                            tag="h3"
                            {...className(
                                `${style.subtitle} heading4 text-uppercase text-basic-100 mb-2`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 100}`}>
                            {title}
                        </Heading>
                    </div>
                </section>

                <div
                    {...className(
                        `${!compact ? 'pt-3 pl-3 pr-3' : 'pl-4 pt-3'}`,
                    )}>
                    <ImageComponent
                        image={icon}
                        alt={companyName}
                        aspectRatio={1.5}
                        className={`${style.portfolioCardImage2} mb-3 ${
                            !compact ? 'mb-xxl-5' : ''
                        }`}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay}`}
                    />

                    <Text
                        tag="p"
                        {...className(
                            `caption text-primary-light-200 text-uppercase mb-2`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {category}
                    </Text>

                    <Heading
                        tag="h3"
                        {...className(
                            `${style.subtitle} heading4 text-uppercase text-basic-100 mb-2`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 100}`}>
                        {title}
                    </Heading>

                    <Text
                        tag="div"
                        {...className(
                            `${style.text} normal text-basic-100 mb-3 mb-xxl-4`,
                        )}
                        data-sal="slide-up"
                        data-sal-delay={`xs-none--lg-${delay + 200}`}>
                        {subtitle}
                    </Text>
                </div>
                <div
                    {...className(
                        `d-flex d ${
                            !compact ? 'align-items-center' : 'align-items-end'
                        } ${
                            region
                                ? 'justify-content-between'
                                : 'justify-content-end'
                        }`,
                    )}>
                    <div {...className(`d-flex pl-3`)}>
                        <IconLocation></IconLocation>
                        <Text
                            tag="p"
                            {...className(
                                `heading4 text-primary-100 ml-1 text-uppercase`,
                            )}
                            data-sal="slide-up"
                            data-sal-delay={`xs-none--lg-${delay + 200}`}>
                            {region}
                        </Text>
                    </div>

                    <div
                        {...className(
                            `d-flex justify-content-end align-items-center`,
                        )}>
                        <ButtonComponent
                            button={{
                                Tag: 'button',
                                label: 'play',
                                type: 'primaryDark',
                                onClick: () =>
                                    onClickVideoButton(companyName, video),
                            }}
                        />

                        <ButtonComponent
                            button={{
                                externalLink: externalLink ?? '',
                                label: 'link',
                                target: '_blank',
                                rel: 'noopener noreferrer',
                                type: 'primaryMedium',
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
*/
