import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Section } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

function BackgroundSection(
    {
        colors,
        type = 'solid',
        children,
        vertexAlignment,
        wrapperClassName,
        bgClassName,
        bgProps,
        tag,
        disabledContainer,
        ...props
    },
    ref,
) {
    const Tag = ['footer', 'section', 'div', Section].includes(tag)
        ? tag
        : 'div';

    const getVariablesCSS = useCallback(() => {
        let rootStyle = {};

        const colorsKeys = ['primary', 'secondary', 'tertiary'];

        if (colors?.length) {
            colors.forEach((color, i) => {
                rootStyle[`--${colorsKeys[i]}`] = style[color];
            });
        }

        if (vertexAlignment) {
            const vertexKeys = ['xs', 'md', 'lg', 'xl', 'xxl'];

            vertexKeys.forEach((key, i) => {
                const revertKeys = [...vertexKeys].slice(0, i).reverse();

                if (key !== 'xs') {
                    rootStyle[`--column-${key}`] =
                        vertexAlignment[key] ??
                        vertexAlignment[
                            revertKeys.find(
                                (keyRevert) =>
                                    typeof vertexAlignment[keyRevert] !==
                                    'undefined',
                            )
                        ];
                    return;
                }
                rootStyle['--column'] = vertexAlignment[key];
            });
        }

        return rootStyle;
    }, [colors, vertexAlignment]);

    return (
        <Tag
            ref={ref}
            style={getVariablesCSS()}
            className={[
                style.main,
                style[type],
                disabledContainer ? style.disabledContainer : null,
                wrapperClassName ? wrapperClassName : null,
            ]
                .filter((item) => !!item)
                .join(' ')}
            {...props}>
            {bgProps ? (
                <div className={style.bgWrapper} {...bgProps}>
                    <div
                        className={`${style.bg} ${
                            bgClassName ? bgClassName : ''
                        }`}
                    />
                </div>
            ) : (
                <div
                    className={`${style.bg} ${bgClassName ? bgClassName : ''}`}
                />
            )}
            {children}
        </Tag>
    );
}

export default forwardRef(BackgroundSection);

BackgroundSection.defaultProps = {
    type: 'solid',
    colors: [],
    vertexAlignment: {},
    tag: 'div',
    disabledContainer: false,
};

export const BackgroundSectionProps = {
    type: PropTypes.oneOf(['solid', 'rectangle', 'rectangleLeft', 'triangle'])
        .isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    vertexAlignment: PropTypes.shape({
        xs: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
    }).isRequired,
    wrapperClassName: PropTypes.string,
    tag: PropTypes.oneOf(['footer', 'section', 'div']),
};

BackgroundSection.propTypes = BackgroundSectionProps;
