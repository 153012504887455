import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Modal as ModalRBC } from '@solublestudio/react-bootstrap-components';
import ButtonComponent from 'IDCButton';

import style from './style.module.scss';

function Tag(props) {
    return (
        <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            {...props}
        />
    );
}

const Modal = ({ children, onToggle = null, bodyClassName, size }, ref) => {
    return (
        <ModalRBC
            ref={ref}
            size={size}
            onToggle={onToggle}
            bodyClassName={bodyClassName}>
            <ButtonComponent
                button={{
                    Tag: Tag,
                    className: style.button,
                    type: 'primaryDark',
                    label: 'close',
                    onClick: () => {
                        ref?.current?.toggle();
                    },
                }}
            />
            {children}
        </ModalRBC>
    );
};

Modal.defaultProps = {
    size: 'xl',
};

Modal.propTypes = {
    bodyClassName: PropTypes.string,
    onToggle: PropTypes.func,
    size: PropTypes.oneOf(['lg', 'sm', 'xl']),
};

export default forwardRef(Modal);
