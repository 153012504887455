import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Row,
    Col,
    Heading,
    Container,
} from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';

import style from './style.module.scss';

export default function HeroSectionAlt({
    title,
    variant = 'primary',
    nextSectionType,
    bgProps,
    titleHidden,
}) {
    const [titleDisplay, setTitleDisplay] = useState('');
    const getColors = useCallback(() => {
        if (variant === 'secondary') {
            return ['primaryLight600', 'primaryMedium800'];
        }
        return ['primaryMedium800', 'primary900'];
    }, [variant]);

    useEffect(() => {
        if (!titleHidden) setTitleDisplay('display2');
        else if (nextSectionType === 'DatoCmsHtmlSection')
            setTitleDisplay('display2');
        else setTitleDisplay('display1');
    }, []);

    return (
        <BackgroundSection
            tag="section"
            type="solid"
            colors={getColors()}
            wrapperClassName={style.bgSection}
            vertexAlignment={{ xs: 4 }}
            {...(!!bgProps && bgProps)}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12 }}
                        className={`${
                            titleDisplay === 'display1'
                                ? style.wrapperTitleBig
                                : style.wrapperTitleSmall
                        } ${style.wrapperTitle}`}>
                        <Heading
                            className={[
                                titleDisplay,
                                'text-secondary',
                                'text-uppercase',
                                titleDisplay === 'display1'
                                    ? style.title
                                    : style.titleSmall,
                            ].join(' ')}
                            tag="h1"
                            data-sal="slide-left">
                            {title}
                        </Heading>
                    </Col>
                </Row>
            </Container>
        </BackgroundSection>
    );
}

HeroSectionAlt.defaultProps = {
    title: 'philosophy philosophy',
    variant: 'primary',
};

HeroSectionAlt.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};
