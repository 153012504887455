export function makeHashable(setCategory) {
    if (typeof window === 'undefined') {
        return;
    }

    const theListener = () => {
        const theHash = window.location.hash.replace('#', '');
        setCategory(theHash && theHash !== 'all' ? theHash : null);
    };

    window.addEventListener('hashchange', theListener);
    theListener();

    return () => {
        window.removeEventListener('hashchange', theListener);
    };
}

export function setupSalAfterFilter(ref) {
    if (ref?.current) {
        setTimeout(() => {
            const allDataSal = ref.current.querySelectorAll('[data-sal]');
            [...allDataSal].forEach((item) => {
                item.classList.add('sal-animate');
            });
        }, 200);
    }
}

export function setupItemsOnFilter(
    items,
    categories,
    categorySelected,
    ifNoCategories,
    ifCategorized,
    ifEmpty,
) {
    if (items?.length > 0) items.sort(sortItemsByName);
    if (!categories?.length) {
        return ifNoCategories(items);
    } else if (categorySelected && items?.length) {
        return ifCategorized(
            items.filter(
                (it) =>
                    !!it.categories.find(
                        (itc) => itc.slug === categorySelected,
                    ),
            ),
        );
    } else {
        return ifEmpty();
    }
}

export const sortItemsByName = (a, b) => {
    if (a.companyName < b.companyName) return -1;
    if (a.companyName > b.companyName) return 1;
    return 0;
};
