import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import ImageComponent from '../ImageComponent';
import BackgroundSection from '../BackgroundSection';

import style from './style.module.scss';

export default function Footer({
    title,
    logo,
    links,
    footerLocations,
    legacyAdviceLinks,
    copyright,
    LinkTag = 'a',
    iconsData,
}) {
    const wrapperRef = useRef();
    useEffect(() => {
        if (typeof window === 'undefined' || !wrapperRef?.current) {
            return;
        }

        const setupHeight = () => {
            if (!wrapperRef.current) {
                return;
            }

            document.documentElement.style.setProperty(
                '--footer-height',
                `${wrapperRef.current.offsetHeight}px`,
            );
        };

        window.addEventListener('resize', setupHeight);
        setTimeout(setupHeight, 1000);

        return () => {
            window.removeEventListener('reisze', setupHeight);
        };
    }, [wrapperRef]);

    // ========================================================
    const [footerIcons, setFooterIcons] = useState([]);

    useEffect(() => {
        const names = {};
        const newFooterIcons = [];
        iconsData?.nodes?.map((item) => {
            const { name } = item;
            if (!names[name]) {
                newFooterIcons.push(item);
                names[name] = true;
            }
        });
        setFooterIcons(newFooterIcons);
    }, [iconsData]);

    return (
        <BackgroundSection
            ref={wrapperRef}
            tag="footer"
            type="solid"
            colors={['transparent', 'primary900']}
            vertexAlignment={{ xs: 0, lg: 1 }}
            wrapperClassName={style.wrapper}>
            <Container
                className={`pt-3 pb-5 pt-lg-6 pb-lg-6 pt-xxl-8 pb-xxl-9`}>
                <Row>
                    <Col
                        col={{ xs: 11, lg: 3 }}
                        offset={{ xs: 1, lg: 2 }}
                        className={`mb-10 mb-lg-0`}>
                        <ImageComponent image={logo} alt={title} />
                        <div className={style.icons}>
                            {footerIcons?.map(({ name, url, urlIcon }) => (
                                <div key={name}>
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                            src={urlIcon}
                                            alt={name}
                                            title={name}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col
                        col={{ xs: 6, lg: 3 }}
                        offset={{ xs: 1, lg: 0 }}
                        className={'mb-5 mb-lg-0'}>
                        <ul {...className(`list-unstyled mb-0`)}>
                            {links &&
                                links.map((link, index) => {
                                    return (
                                        <li
                                            key={index}
                                            {...className(
                                                `mb-2 ${style.link}`,
                                            )}>
                                            <LinkTag
                                                href={
                                                    link.externalLink
                                                        ? link.externalLink
                                                        : link.link
                                                }
                                                {...className(
                                                    'normal text-basic-100 text-uppercase',
                                                )}>
                                                {link.title}
                                            </LinkTag>
                                        </li>
                                    );
                                })}
                        </ul>
                    </Col>
                    <Col
                        col={{ xs: 6, lg: 4 }}
                        offset={{ xs: 1, lg: 0 }}
                        className={`d-lg-flex justify-content-between`}>
                        {footerLocations &&
                            footerLocations.map((region, index) => {
                                return (
                                    <ul
                                        key={index}
                                        {...className(
                                            `list-unstyled mb-3 mb-lg-0`,
                                        )}>
                                        <li
                                            {...className(
                                                `mb-1 normal text-primary-400 text-uppercase`,
                                            )}>
                                            <LinkTag
                                                href={
                                                    region.parent.externalLink
                                                        ? region.parent
                                                            .externalLink
                                                        : region.parent.link
                                                }>
                                                {region.parent.title}
                                            </LinkTag>
                                        </li>
                                        {region.childs &&
                                            region.childs.map(
                                                (location, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            {...className(
                                                                `mb-1 normal text-primary-100`,
                                                            )}>
                                                            <LinkTag
                                                                href={
                                                                    location.externalLink
                                                                        ? location.externalLink
                                                                        : location.link
                                                                }>
                                                                {location.title}
                                                            </LinkTag>
                                                        </li>
                                                    );
                                                },
                                            )}
                                    </ul>
                                );
                            })}
                    </Col>
                </Row>
                <Row className="mt-6 mt-lg-5 mt-xxl-8">
                    <Col col={{ xs: 12, lg: 7 }} offset={{ xs: 1, lg: 5 }}>
                        <ul {...className(`list-unstyled mb-0 d-flex`)}>
                            {legacyAdviceLinks &&
                                legacyAdviceLinks.map((link, index) => {
                                    return (
                                        <li
                                            key={index}
                                            {...className(
                                                `mb-2 ${style.link} ${style.separator}`,
                                            )}>
                                            <LinkTag
                                                target={link.target}
                                                href={link.link}
                                                {...className(
                                                    'caption text-primary-200 text-uppercase',
                                                )}>
                                                {link.title}
                                            </LinkTag>
                                        </li>
                                    );
                                })}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col
                        col={{ xs: 12, lg: 2 }}
                        offset={{ xs: 1, lg: 5 }}
                        className="d-flex justify-lg-content-end">
                        <Text className="caption text-primary-200 text-uppercase">
                            {copyright}
                        </Text>
                    </Col>
                </Row>
            </Container>
        </BackgroundSection>
    );
}

Footer.propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, link: PropTypes.string }),
    ),
    footerLocations: PropTypes.arrayOf(
        PropTypes.shape({
            parent: PropTypes.shape({
                title: PropTypes.string,
                link: PropTypes.string,
            }),
            childs: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    link: PropTypes.string,
                }),
            ),
        }),
    ),
    legacyAdviceLinks: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, link: PropTypes.string }),
    ),
    copyright: PropTypes.string,
};
