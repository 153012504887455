import React from 'react';

import { Container, Row, Col } from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';
import HTMLTextComponent from '../HTMLTextComponent';

import style from './style.module.scss';

export default function HTMLSection({ text }) {
    return (
        <BackgroundSection
            colors={['primaryMedium800', 'primaryLight600', 'primaryLight500']}
            vertexAlignment={{ xs: 4 }}
            type="triangle"
            wrapperClassName={style.wrapper}>
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 8 }} offset={{ lg: 2 }}>
                        <HTMLTextComponent text={text}></HTMLTextComponent>
                    </Col>
                </Row>
            </Container>
        </BackgroundSection>
    );
}
