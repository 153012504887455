import React from 'react';
import PropTypes from 'prop-types';

import { Text, Heading } from '@solublestudio/react-bootstrap-components';

const PointComponent = ({ number, title, description, delay }) => {
    return (
        <>
            <Text
                className="text-basic-100 heading2 text-uppercase mb-2"
                tag="p"
                data-sal="slide-right"
                data-sal-delay={`xs-none--lg-${delay}`}>
                {number}
            </Text>
            <Heading
                className="text-basic-100 heading2 text-uppercase mb-2 mb-lg-4"
                tag="h3"
                data-sal="slide-right"
                data-sal-delay={`xs-none--lg-${delay + 100}`}>
                {title}
            </Heading>
            <Text
                className="text-basic-200 medium"
                tag="p"
                data-sal="slide-right"
                data-sal-delay={`xs-none--lg-${delay + 200}`}>
                {description}
            </Text>
        </>
    );
};

PointComponent.defaultProps = {
    delay: 0,
};

PointComponent.propTypes = {
    number: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    delay: PropTypes.number,
};

export default PointComponent;
