import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    Col,
    Row,
    Text,
    Container,
    useIsMobile,
    className,
    Section,
} from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';
import Carousel from '../Carousel';
import ImageComponent from '../ImageComponent';
import FeatureTextComponent from '../FeatureTextComponent';
import ButtonComponent from 'IDCButton';

import style from './style.module.scss';

const FeatureCarouselSection = ({
    preTitle,
    title,
    description,
    tags,
    items,
    cta,
}) => {
    const isMobile = useIsMobile();

    const arrayTags = useMemo(
        () =>
            tags
                .trim()
                .split(';')
                .filter((i) => !!i),
        [tags],
    );

    return (
        <BackgroundSection
            tag={Section}
            type="triangle"
            colors={[
                'primaryLight500',
                isMobile ? 'primaryLight600' : 'primaryLight500',
                'primaryLight600',
            ]}
            vertexAlignment={{ xs: 8 }}>
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 6 }} offset={{ lg: 1 }}>
                        <FeatureTextComponent
                            preTitle={preTitle}
                            title={title}
                            description={description}
                            dark
                        />
                        {arrayTags?.length && (
                            <div
                                {...className(
                                    'd-flex align-items-center flex-nowrap mt-3 mt-lg-5',
                                )}>
                                {arrayTags.map((tag, i) => (
                                    <Text
                                        data-sal="slide-right"
                                        data-sal-delay={300 + 300 * i}
                                        key={`tag-${tag}-${i}`}
                                        className="text-basic-100 normal mb-3 mb-lg-5 d-inline-block d-lg-block heading4 text-uppercase mr-4"
                                        tag="span">
                                        {tag}
                                    </Text>
                                ))}
                            </div>
                        )}
                        {cta && (
                            <div data-sal="slide-right" data-sal-delay="400">
                                <ButtonComponent
                                    button={{
                                        ...cta,
                                        type: 'secondaryArrowLight',
                                    }}
                                />
                            </div>
                        )}
                    </Col>
                    <Col
                        col={{ xs: 12, lg: 4, xxl: 3 }}
                        offset={{ lg: 1, xxl: 2 }}
                        mt={{ xs: 3, lg: 0 }}
                        className={['d-lg-flex', 'align-items-lg-center'].join(
                            ' ',
                        )}>
                        {items?.length > 0 ? (
                            <Carousel
                                className={style.wrapperCarousel}
                                settings={{
                                    autoplay: true,
                                    autoplayTimeout: 2500,
                                    loop: true,
                                }}>
                                {items.map(({ picture, text }, i) => (
                                    <div
                                        key={`picture-${text}-${i}`}
                                        {...className('text-center')}>
                                        <ImageComponent
                                            image={picture}
                                            className={`mb-3 mb-lg-5 ${style.image}`}
                                        />
                                        <Text
                                            className="text-basic-100 text-uppercase heading2"
                                            tag="div">
                                            {text}
                                        </Text>
                                    </div>
                                ))}
                            </Carousel>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </BackgroundSection>
    );
};

FeatureCarouselSection.defaultProps = {
    items: [],
};

FeatureCarouselSection.propTypes = {
    ...FeatureTextComponent.propTypes,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            picture: PropTypes.any,
            text: PropTypes.string,
        }),
    ),
};

export default FeatureCarouselSection;
