import React from 'react';

import {
    GridContainer,
    GridBox,
    Heading,
    Text,
} from '@solublestudio/react-bootstrap-components';

import MediaComponent from '../MediaComponent';
import BackgroundSection from '../BackgroundSection';

import style from './style.module.scss';

export default function FeatureSection({
    picture,
    title,
    pretitle,
    description,
}) {
    return (
        <BackgroundSection
            tag="section"
            type="rectangleLeft"
            colors={['secondary', 'basic100']}
            vertexAlignment={{ xs: 9, lg: 5 }}
            bgClassName={style.bgSection}>
            <GridContainer className={`${style.featureSection}`}>
                <GridBox
                    Tag="div"
                    atts={{ 'data-sal': 'slide-left' }}
                    className={`heading1 font-weight-normal text-primary-900 pt-10 pb-22 pt-lg-20 pb-lg-20 pt-xxl-21 pb-xxl-21 ${style.featureSectionTitle}`}>
                    <Text
                        tag="p"
                        className={`heading4 text-uppercase mb-3 mb-lg-4 mb-xxl-5`}>
                        {pretitle}
                    </Text>
                    <Heading tag="h3" className={`heading2 text-uppercase`}>
                        {title}
                    </Heading>
                    {description && (
                        <Text tag="div" className={`medium mt-3 mt-xxl-5`}>
                            {description}
                        </Text>
                    )}
                </GridBox>
                <GridBox className={`${style.featureSectionImageBox}`}>
                    {picture && (
                        <div className={style.featureImageContainer}>
                            <MediaComponent
                                picture={picture}
                                alt={title}
                                aspectRatio={1.5}
                                className={
                                    picture.isImage
                                        ? style.featureSectionImage
                                        : style.video
                                }
                            />
                        </div>
                    )}
                </GridBox>
            </GridContainer>
        </BackgroundSection>
    );
}
