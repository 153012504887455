import React from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Section,
} from '@solublestudio/react-bootstrap-components';

import BackgroundSection from '../BackgroundSection';
import PointComponent from '../PointComponent';

import style from './style.module.scss';
function PointsSection({ points }) {
    return (
        <BackgroundSection
            type="triangle"
            tag={Section}
            vertexAlignment={{ xs: 4 }}
            colors={['primary', 'primaryLight600', 'primaryLight500']}>
            <Container>
                <Row>
                    {points.map((point, i) => (
                        <Col
                            key={i}
                            col={{ xs: 12, lg: 4 }}
                            offset={{ lg: 1 }}
                            className={[style.pointItem, 'mb-lg-0'].join(' ')}>
                            <PointComponent
                                {...point}
                                delay={i * 200}
                                key={`point-${i}`}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </BackgroundSection>
    );
}

PointsSection.defaultProps = {
    points: [],
};

PointsSection.propTypes = {
    points: PropTypes.arrayOf(PointComponent.propTypes),
};

export default PointsSection;
