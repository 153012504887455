import React, { useMemo, useRef, useState } from 'react';

import {
    GridContainer,
    GridBox,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import FastFactsCardII from '../FastFactsCardII';
import BackgroundSection from '../BackgroundSection';
import style from './style.module.scss';
import { setupItemsOnFilter } from '../../utils';

const listToMatrix = (list, elementsPerSubArray) => {
    let matrix = [],
        k = -1;
    list.forEach((el, i) => {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }
        matrix[k].push(list[i]);
    });
    return matrix;
};

const addOrderToItems = (items) =>
    items.map((item, i) => ({
        ...item,
        order: i,
    }));

export default function FastFactsSectionII({
    categories = [],
    compact = false,
    title,
    items,
}) {
    const sectionRef = useRef();
    const [categorySelected, setCategorySelected] = useState();

    const [firstBox, secondBox, otherBoxes] = useMemo(() => {
        return setupItemsOnFilter(
            items,
            categories,
            categorySelected,
            (items) => {
                const portfolioOrdered = addOrderToItems(items);
                return compact
                    ? [
                          portfolioOrdered.slice(0, 2),
                          portfolioOrdered.slice(2, 4),
                          listToMatrix(
                              portfolioOrdered.slice(
                                  4,
                                  portfolioOrdered.length,
                              ),
                              3,
                          ),
                      ]
                    : [[], [], listToMatrix(portfolioOrdered, 3)];
            },
            (items) => [[], [], listToMatrix(addOrderToItems(items), 3)],
            () => [[], [], []],
        );
    }, [categories, items, categorySelected, compact]);

    const renderCard = (card, index) => {
        return (
            <FastFactsCardII
                key={index}
                {...card}
                compact={compact}
                wrapperClassName={style.card}
                delay={index * 200}
            />
        );
    };

    return (
        <BackgroundSection
            ref={sectionRef}
            tag="section"
            colors={compact ? ['primaryLight700'] : ['secondary']}
            type="solid"
            wrapperClassName={style.wrapper}
            {...(!categories?.length && { 'data-hide-on-filter': true })}
            {...(!!categorySelected && { 'data-is-filter': true })}>
            {title && (
                <Container>
                    <Row>
                        <Col offset={{ lg: 1 }}>
                            <Heading
                                tag="h4"
                                className="heading1 text-primary-900 text-uppercase mb-2 mb-xxl-3 mt-6 mt-lg-5 mt-xxl-8">
                                {title}
                            </Heading>
                        </Col>
                    </Row>
                </Container>
            )}
            <GridContainer>
                <GridBox className={style.box}>
                    {otherBoxes.map((box, i) =>
                        box.map((card, index) => renderCard(card, index)),
                    )}
                </GridBox>
            </GridContainer>
        </BackgroundSection>
    );
}
