import React, { forwardRef, useMemo } from 'react';

import { Tabs as RBCTabs } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const Tabs = forwardRef(
    (
        {
            wrapperClassName,
            liClassName,
            linkClassName,
            links,
            allCategoriesText,
            allCategoriesSlug,
            categorySelected,
            ...props
        },
        ref,
    ) => {
        const items = useMemo(
            () => [
                {
                    title: allCategoriesText,
                    href: `#${allCategoriesSlug}`,
                    active:
                        categorySelected === allCategoriesSlug ||
                        !categorySelected,
                },
                ...links.map((l) => ({
                    title: l.title,
                    href: `#${l.slug}`,
                    active: categorySelected === l.slug,
                })),
            ],
            [links, allCategoriesText, allCategoriesSlug, categorySelected],
        );

        return (
            <>
                <div className={style.anchors}>
                    <div id="all" />
                    {links.map(({ slug }, i) => (
                        <div key={i} id={slug} />
                    ))}
                </div>
                <RBCTabs
                    ref={ref}
                    className={`${wrapperClassName ? wrapperClassName : ''} ${
                        style.wrapper
                    }`}
                    liClassName={`${style.tab} ${
                        liClassName ? liClassName : ''
                    }`}
                    linkClassName={`${style.link} ${
                        linkClassName ? linkClassName : ''
                    }`}
                    activeClassName={style.activeItem}
                    items={items}
                    {...props}
                />
            </>
        );
    },
);

export default Tabs;
