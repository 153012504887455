import React, { useMemo } from 'react';
import GatsbyFooter from '@solublestudio/idc-design-system/src/components/Gatsby/GatsbyFooter';
import { Link, useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

export default function Footer({ title, navs, iconsData }) {
    const _ = useTranslate();
    const [
        links,
        europe,
        latinAmerica,
        northAmerica,
        legacyAdviceLinks,
    ] = useMemo(() => {
        return [
            navs?.length
                ? navs[0].childLinks.map((l) => ({
                      title: l.label,
                      link: l.slug,
                      target: l.openInNewWindow ? '_blank' : '_self',
                  }))
                : [],
            navs?.length > 1
                ? {
                      parent: {
                          title: navs[1].label,
                          link: navs[1].slug,
                          target: navs[1].openInNewWindow ? '_blank' : '_self',
                      },
                      childs: navs[1].childLinks.map((l) => ({
                          title: l.label,
                          link: l.slug,
                          target: l.openInNewWindow ? '_blank' : '_self',
                      })),
                  }
                : [],
            navs?.length > 2
                ? {
                      parent: {
                          title: navs[2].label,
                          link: navs[2].slug,
                          target: navs[2].openInNewWindow ? '_blank' : '_self',
                      },
                      childs: navs[2].childLinks.map((l) => ({
                          title: l.label,
                          link: l.slug,
                          target: l.openInNewWindow ? '_blank' : '_self',
                      })),
                  }
                : [],
            navs?.length > 3
                ? {
                      parent: {
                          title: navs[3].label,
                          link: navs[3].slug,
                          target: navs[3].openInNewWindow ? '_blank' : '_self',
                      },
                      childs: navs[3].childLinks.map((l) => ({
                          title: l.label,
                          link: l.slug,
                          target: l.openInNewWindow ? '_blank' : '_self',
                      })),
                  }
                : [],
            navs?.length > 4
                ? navs[4].childLinks.map((l) => ({
                      title: l.label,
                      link: l.slug,
                      target: l.openInNewWindow ? '_blank' : '_self',
                  }))
                : [],
        ];
    }, [navs]);

    return (
        <GatsbyFooter
            LinkTag={Link}
            title={title}
            copyright={_['footer.copyright'].text}
            links={links}
            footerLocations={[europe, latinAmerica, northAmerica]}
            legacyAdviceLinks={legacyAdviceLinks}
            iconsData={iconsData}
        />
    );
}
