import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.scss';

export default function HTMLTextComponent({ text, className }) {
    return (
        <div
            className={[
                style.HTMLTextComponent,
                className ? className : '',
            ].join(' ')}
            dangerouslySetInnerHTML={{
                __html: text,
            }}
        />
    );
}

HTMLTextComponent.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};
