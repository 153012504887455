import React from 'react';
import PropTypes from 'prop-types';

import {
    Text,
    className,
    GridContainer,
    GridBox,
    GridWrapper,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from 'IDCButton';
import ImageComponent from '../ImageComponent';
import BackgroundSection from '../BackgroundSection';

import style from './style.module.scss';

export default function HighlightedSection({
    companyName,
    image,
    icon,
    text,
    externalLink,
    delay,
    previousSectionType,
}) {
    return (
        <BackgroundSection
            tag="section"
            colors={[
                previousSectionType === 'DatoCmsHeroSectionAlt' ||
                previousSectionType === 'DatoCmsDescriptionSection'
                    ? 'primaryLight600'
                    : 'secondary',
            ]}
            wrapperClassName={
                previousSectionType === 'DatoCmsHeroSectionAlt'
                    ? style.afterHero
                    : ''
            }
            type="solid">
            <GridContainer className={`${style.highlightedContainer}`}>
                <GridBox
                    className={`${style.highlightedWrapper} pb-11 pb-lg-8 pb-xxl-11`}>
                    <ImageComponent
                        image={image}
                        className={style.highlightedWrapperImage}
                    />
                    <GridWrapper>
                        <div
                            {...className(
                                `${style.highlightedCard} bg-primary-light-700`,
                            )}>
                            <div {...className(`pt-5 pl-5 pr-5`)}>
                                {icon && (
                                    <ImageComponent
                                        image={icon}
                                        alt={companyName}
                                        aspectRatio={1.5}
                                        className={`${style.imageWrapper} mb-5`}
                                        data-sal="slide-up"
                                        data-sal-delay={`xs-none--lg-${delay}`}
                                    />
                                )}
                                <Text
                                    tag="div"
                                    {...className(`normal text-basic-100 mb-3`)}
                                    data-sal="slide-up"
                                    data-sal-delay={`xs-none--lg-${
                                        delay + 200
                                    }`}>
                                    {text}
                                </Text>
                            </div>
                            <div
                                {...className(
                                    `d-flex d align-items-center justify-content-end`,
                                )}>
                                <div
                                    {...className(
                                        `d-flex justify-content-end align-items-center ${style.highlightedCardButtons}`,
                                    )}>
                                    {externalLink && (
                                        <ButtonComponent
                                            button={{
                                                externalLink:
                                                    externalLink ?? '',
                                                label: 'link',
                                                target: '_blank',
                                                rel: 'noopener noreferrer',
                                                type: 'primaryMedium',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </GridWrapper>
                </GridBox>
            </GridContainer>
        </BackgroundSection>
    );
}

HighlightedSection.propTypes = {
    image: PropTypes.any,
    companyName: PropTypes.string,
    icon: PropTypes.any,
    text: PropTypes.string,
    externalLink: PropTypes.string,
    delay: PropTypes.number,
    region: PropTypes.string,
};
