import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Footer from '../../Footer';

export default function GatsbyFooter(props) {
    const data = useStaticQuery(graphql`
        {
            logo: file(
                sourceInstanceName: { eq: "img-ds" }
                name: { eq: "logo" }
            ) {
                publicURL
            }
        }
    `);

    return <Footer logo={data.logo.publicURL} {...props} />;
}
