import React, { useMemo } from 'react';
import GatsbyHeader from '@solublestudio/idc-design-system/src/components/Gatsby/GatsbyHeader';
import { Link } from '@solublestudio/gatsby-theme-soluble-source';

import '@solublestudio/idc-design-system/src/scss/fonts.scss';
import '@solublestudio/idc-design-system/src/scss/transitions.module.scss';
import '@solublestudio/idc-design-system/src/scss/cookiehub.scss';

export default function Header({ navs, title }) {
    const rightItems = useMemo(
        () =>
            [
                ...navs.filter((n) => n.type !== 'button'),
                ...navs.filter((n) => n.type === 'button'),
            ]
                .filter(({ hidden }) => !hidden)
                .map((n) => {
                    const { childLinks } = n;
                    const isDropdown = childLinks?.length > 0;
                    let subitems = [];
                    if (isDropdown) {
                        subitems = childLinks.reduce((prev, curr) => {
                            return [
                                ...prev,
                                {
                                    ...curr,
                                    title: curr?.label,
                                    href: `/${curr?.slug}`,
                                },
                            ];
                        }, []);
                    }
                    return {
                        title: n.label,
                        href: n.slug,
                        Tag: Link,
                        scheme: 'gatsby',
                        isButton: n.type === 'button',
                        type: isDropdown > 0 ? 'list' : 'link',
                        subitems,
                    };
                }),
        [navs],
    );

    return <GatsbyHeader rightItems={rightItems} logoAlt={title} Tag={Link} />;
}
