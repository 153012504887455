import React, {
    useCallback,
    useRef,
    useState,
    useMemo,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
    GridContainer,
    GridBox,
    Container,
    Row,
    Col,
    Heading,
} from '@solublestudio/react-bootstrap-components';

import TeamCard from '../TeamCard';
import TeamPopUp from '../TeamPopUp';
import BackgroundSection from '../BackgroundSection';
import Filters from '../Filters';

import style from './style.module.scss';
import {
    makeHashable,
    setupSalAfterFilter,
    setupItemsOnFilter,
} from '../../utils';

const formatMembers = (teamMembers) => {
    let index = 0;
    const b = [];
    while (index < teamMembers.length) {
        if (index == 3 || index % 11 == 3) {
            b.push(teamMembers.slice(index, index + 2));
            index += 2;
        } else {
            b.push(teamMembers.slice(index, index + 3));
            index += 3;
        }
    }
    return b;
};

function TeamList({
    teamMembers,
    title,
    categories,
    allCategoriesText,
    pretitle,
}) {
    const refModal = useRef({});
    const sectionRef = useRef();
    const [itemSelected, setItemSelected] = useState({});
    const [categorySelected, setCategorySelected] = useState();

    const onClickDetail = useCallback((member) => {
        setItemSelected(member);

        refModal?.current?.toggle();
    }, []);

    const members = useMemo(() => {
        return setupItemsOnFilter(
            teamMembers,
            categories,
            categorySelected,
            (teamMembers) => formatMembers(teamMembers),
            (teamMembers) => formatMembers(teamMembers),
            () => [],
        );
    }, [teamMembers, categories, categorySelected]);

    useEffect(() => makeHashable(setCategorySelected), [setCategorySelected]);

    useEffect(() => {
        if (categorySelected) {
            setupSalAfterFilter(sectionRef);
        }
    }, [categorySelected, sectionRef]);

    return (
        <BackgroundSection
            ref={sectionRef}
            colors={['secondary']}
            type="solid"
            tag="section"
            wrapperClassName={style.wrapper}
            {...(!categories?.length && { 'data-hide-on-filter': true })}
            {...(!!categorySelected && { 'data-is-filter': true })}>
            {!!categories?.length && (
                <div>
                    <Container>
                        <Row>
                            <Col>
                                <Filters
                                    wrapperClassName={style.filters}
                                    allCategoriesText={allCategoriesText}
                                    allCategoriesSlug="all"
                                    categorySelected={categorySelected}
                                    links={categories}></Filters>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
            {pretitle && (
                <Container>
                    <Row>
                        <Col offset={{ lg: 1 }}>
                            <Heading
                                tag="h4"
                                className="heading1 text-basic-900 text-uppercase mb-2 mb-xxl-3 mt-10 mt-lg-14 mt-xxl-21">
                                {pretitle}
                            </Heading>
                        </Col>
                    </Row>
                </Container>
            )}
            <GridContainer>
                {members.map((box, i) => {
                    return (
                        <GridBox
                            key={i}
                            className={style.box}
                            atts={{ 'data-index': i }}>
                            {box?.length > 0 &&
                                box.map((card, index) => {
                                    return card ? (
                                        <TeamCard
                                            key={index}
                                            {...card}
                                            delay={index * 200}
                                            onClickDetail={onClickDetail}
                                            wrapperClassName={style.card}
                                        />
                                    ) : (
                                        <div />
                                    );
                                })}
                        </GridBox>
                    );
                })}
                {!!title && (
                    <GridBox
                        Tag="h2"
                        className={`display1 text-basic-100 text-uppercase ${style.titleBox}`}>
                        {title}
                    </GridBox>
                )}
            </GridContainer>
            <TeamPopUp ref={refModal} member={itemSelected} />
        </BackgroundSection>
    );
}

TeamList.propTypes = {
    teamMembers: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
};

export default TeamList;
