import React, { forwardRef } from 'react';
import { Navbar } from '@solublestudio/react-bootstrap-components';
import PropTypes from 'prop-types';

import style from './style.module.scss';

function Header({ logo, rightItems, ...props }, ref) {
    return (
        <header>
            <Navbar
                ref={ref}
                isFixed={true}
                logo={logo}
                {...props}
                navs={[
                    rightItems
                        ? {
                              align: 'right',
                              items: rightItems.map((i) => ({
                                  ...i,
                                  activeClassName: style.activeItem,
                                  extraClassName: `position-relative ${
                                      i.isButton
                                          ? style.separateItem
                                          : style.defaultItem
                                  }`,
                              })),
                          }
                        : null,
                ]}
            />
        </header>
    );
}

export default forwardRef(Header);

Header.propTypes = {
    logo: PropTypes.string,
    rightItems: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        }),
    ),
};
