import * as am4core from '@amcharts/amcharts4/core';

export const getUrls = (companies) => {
    const urls = {};
    companies.map((company) => {
        const { iso3166: id } = company;
        // ISEXIST LOGO
        const img1 = company?.primaryIcon?.file?.childImageSharp?.resize?.src;
        const img2 = company?.secondaryIcon?.file?.childImageSharp?.resize?.src;
        const url = !img1 ? img2 : img1;
        // SAVING URL PER COUNTRY
        if (!urls[id]) urls[id] = [];
        urls[id].push(url);
    });
    return urls;
};

export const adapter = (companies) => {
    let countries = {};
    let urls = getUrls(companies);
    let data = [];
    companies.forEach((company) => {
        const { iso3166: id, isexit } = company;
        if (!countries[id] && !isexit) {
            countries[id] = true;
            data.push({
                id,
                url: urls[id],
                fill: am4core.color('#242BD3'),
                isCompany: true,
            });
        }
    });
    // w
    return data;
};

export const getISO3166 = (companies) => {
    let countries = {};
    companies.forEach((company) => {
        const { iso3166: id, isexit } = company;
        if (!isexit) {
            if (!countries[id]) {
                countries[id] = 1;
            } else countries[id] += 1;
        }
    });
    // console.log(countries);
    return countries;
};

export const markerData = (markers, dataCountries, companies) => {
    let newMarkers = [];
    const urls = getUrls(companies);
    markers.forEach((el) => {
        const quantity = dataCountries[el?.name];
        if (quantity) {
            const URL_BACK = 'https://marker-back.herokuapp.com';
            const image = `${URL_BACK}/${quantity}.png`;
            const auxObj = {
                ...el,
                url: urls[el?.name],
                latitude: parseFloat(el?.latitude),
                longitude: parseFloat(el?.longitude),
                image,
            };
            newMarkers.push(auxObj);
        }
    });
    // console.log(newMarkers);
    return newMarkers;
};

export const getCoords = (data) => {
    const { latitude, longitude } = data;
    return { latitude, longitude };
};
